<template>
  <div
    ref="tabContainer"
    :class="`custom-tab-map bg-ems-gray800 overflow-hidden p-[20px] ${
      isCollapse ? 'w-[100px]' : 'w-full'
    }`"
  >
    <div v-if="isCollapse" class="flex justify-center items-center">
      <img
        src="@/static/img/icon/nav-expand.png"
        class="cursor-pointer"
        @click="isCollapse = false"
      />
    </div>
    <div v-if="!isCollapse" class="flex justify-between items-center">
      <p class="text-ems-gray200 text-lg uppercase">
        {{ t('user_group.map') }}
      </p>
      <img
        src="@/static/img/icon/nav-collapse.png"
        alt="nav"
        @click="isCollapse = true"
        class="cursor-pointer"
      />
    </div>
    <div
      class="w-full rounded-xl overflow-hidden mt-[15px] h-[calc(100%-48px)]"
    >
      <GoongMap
        ref="map"
        v-show="!isCollapse"
        class="w-full"
        :markers="markers"
        :center="center"
      />
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, watch, ref } from 'vue';
import GoongMap from '@/components/goong-map/index.vue';
import { MARKER_TYPE } from '@/util/common-constant.js';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { useStore } from 'vuex';

var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);
const { t } = useI18n();
const { state, dispatch } = useStore();
const isCollapse = ref(false);
const map = ref();
const tabContainer = ref();
const item = ref({
  avatar: '',
  userName: 'test',
  location: 'location 1',
  createdTime: '06/06/2024 11:12:53',
});
const listStatus = computed(() => state.coordinator.listStatus);
const markers = ref([]);
watch(
  () => listStatus.value,
  (val) => {
    getMarkers(val);
  },
  {
    deep: true,
  }
);
const getMarkers = (list) => {
  let arr = [];
  for (let key in list) {
    if (list[key].location) {
      let str = ` <div class="content-wrapper">
        <div class="relative inline-block flex-shrink-0">
          <img
          src="${
            list[key].avatar
              ? list[key].avatar
              : require('@/static/img/no-img.png')
          }"
            width="64"
            height="64"
            class="rounded-full !w-[64px] h-[64px] flex-shrink-0 object-cover"
          />
          <img
            src="/assets/ems/${list[key].online ? 'online' : 'offline'}.png"
            width="16"
            height="16"
            class="w-[16px] h-[16px] absolute bottom-0 right-0"
          />
        </div>
        <div class="flex flex-col justify-center gap-[4px]">
          <span class="text-base text-ems-white font-semibold whitespace-nowrap">ĐC. ${
            list[key].name ? list[key].name : t('object_tracing.unknown')
          }</span>
              <div class="font-semibold text-ems-gray200 ">${
                list[key].location.address
              }</div>
              `;
      if (!list[key].online) {
        str =
          str +
          `<li class="text-[12px] font-semibold text-ems-gray500">${dayjs(
            list[key].lastOnline
          ).fromNow()}</li>
            </div>
          </div>`;
      } else {
        str =
          str +
          `</div>
              </div>`;
      }
      arr.push({
        lat: list[key].location.latitude,
        lng: list[key].location.longitude,
        type: MARKER_TYPE.DEFAULT,
        id: list[key].userId,
        infoWindow: `<div class="content-wrapper p-16px text-ems-white  font-semibold ">${list[key].name}</div>`,
        detailInfo: str,
      });
    }
  }
  markers.value = arr;
  map.value.setMarker(markers.value);
  if (arr.length > 0) map.value.moveToLocation(arr[0].lat, arr[0].lng);
};
// const directions = {
//   origin: '21.05186581880781,105.79792615161264',
//   destination: '21.048072747697425,105.80058172435054',
//   vehicle: 'car',
// };
const resizeMap = debounce(() => {
  if (map.value) map.value.resize();
}, 0);
const center = ref({ lat: 21.05186581880781, lng: 105.79792615161264 });
onMounted(() => {
  new ResizeObserver(resizeMap).observe(tabContainer.value);
});
</script>

<style>

.custom-tab-map {
  transition: width 0.5s ease;
}

</style>
