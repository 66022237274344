<template>
  <div
    v-if="itemValue.otherInfo"
    class="w-full h-[90px] flex items-center justify-between rounded-lg relative px-[20px]"
  >
    <div class="flex items-center justify-center space-x-[23px]">
      <div
        :class="`rounded-full w-[64px] h-[64px] ${
          itemValue.otherInfo.peerType === 1 ? 'bg-white ' : 'bg-ems-boTro2_500'
        } flex justify-center items-center relative flex-shrink-0`"
      >
        <div
          class="rounded-full w-[64px] h-[64px] flex justify-center items-center overflow-hidden flex-shrink-0"
        >
          <img
            v-if="
              itemValue.otherInfo.peerAvatarUrl &&
              itemValue.otherInfo.peerType === 1
            "
            alt="avatar"
            :src="itemValue.otherInfo.peerAvatarUrl"
            class="w-ful h-auto"
          />
          <div v-else>
            <p class="font-semibold text-3xl uppercase text-white">
              {{ itemValue.otherInfo.peerName.charAt(0) }}
            </p>
          </div>
        </div>
        <div
          v-if="itemValue.otherInfo.status"
          class="h-[16px] w-[16px] border-2 rounded-full border-ems-tag1 bg-ems-boTro4_600 absolute bottom-0 right-0"
        ></div>
      </div>
      <div class="space-y-[8px]">
        <p
          v-if="itemValue.otherInfo.peerName"
          class="text-white font-semibold text-[16px]"
        >
          {{ itemValue.otherInfo.peerName }}
        </p>
        <p
          v-if="itemValue && itemValue.otherInfo.peerType === 1"
          class="text-ems-gray200 font-semibold text-[12px]"
        >
          {{
            itemValue.otherInfo.status
              ? t('coordinator.online')
              : t('coordinator.offline')
          }}
        </p>
        <p v-else class="text-ems-gray200 font-semibold text-[12px]">
          {{ totalElementsMember }} {{ t('coordinator.member') }}
        </p>
      </div>
    </div>
    <div v-if="isShowAction" class="flex items-center space-x-[17px]">
      <!-- <img alt="search" src="@/static/img/icon/search-message.svg" /> -->
      <a-dropdown :trigger="['click']">
        <img
          alt="setting"
          src="@/static/img/icon/setting-message.svg"
          class="cursor-pointer rounded-full"
          @click.prevent
        />
        <template #overlay>
          <div>
            <div
              v-for="item in currentItems"
              :key="item.type"
              @click="handleClick(item.type)"
              @mouseenter="hoveredItem = item.type"
              @mouseleave="hoveredItem = ''"
              :class="[
                hoveredItem === item.type ? 'bg-ems-gray700' : 'bg-ems-gray800',
                'flex cursor-pointer h-[39px] w-[168px] flex items-center',
              ]"
            >
              <img :src="item.icon" alt="icon" class="pl-[8px] pr-[12px]" />
              <p
                :class="[
                  hoveredItem === item.type
                    ? 'font-semibold text-white'
                    : 'font-normal text-ems-gray300',
                  'text-[16px]',
                ]"
              >
                {{ item.text }}
              </p>
            </div>
          </div>
        </template>
      </a-dropdown>
    </div>
    <div v-if="isShowConnect">
      <button
        v-if="itemValue.otherInfo && idConnect === itemValue.otherInfo.peerId"
        @click="handleCancel"
        class="h-[37px] w-[85px] flex items-center justify-center bg-ems-main2 rounded-lg text-white"
      >
        {{ t('common.cancel_short') }}
      </button>
      <button
        v-else
        @click="handleConnect"
        class="flex items-center justify-center bg-ems-main2 rounded-lg text-white py-2 px-3"
      >
        {{ t('coordinator.connect') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed, ref, watch } from 'vue';
import ModalDelete from './modal-delete.vue';
import VueTypes from 'vue-types';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { TAB_COORDINATION, TAB_WALKIE } from '@/util/common-constant.js';
import { useRoute, useRouter } from 'vue-router';
const { dispatch } = useStore();
const route = useRoute();
const router = useRouter();
const props = defineProps({
  name: VueTypes.string.def(''),
  message: VueTypes.string.def(''),
  isShowAction: VueTypes.bool.def(),
  isShowConnect: VueTypes.bool.def(),
});
const { state } = useStore();
const { t } = useI18n();
const itemValue = computed(() => state.coordinator.itemValue || '');
const isShowMember = computed(() => state.coordinator.isShowMember || '');
const idConnect = computed(() => state.coordinator.idConnected);
const roomId = computed(() => state.coordinator.roomID);
const totalElementsMember = computed(
  () => state.coordinator.totalElementsMember || ''
);
const isPopupDelete = ref(false);
const hoveredItem = ref('');
const items = ref([
  {
    type: 'del-all',
    icon: require('@/static/img/icon/send-video.svg'),
    text: t('coordinator.del_all_messages'),
  },
  // {
  //   type: 'pin',
  //   icon: require('@/static/img/icon/send-image.svg'),
  //   text: t('coordinator.pin'),
  // },
  {
    type: 'favourite',
    icon: require('@/static/img/icon/send-image.svg'),
    text: t('coordinator.add_like'),
  },
]);
const currentItems = computed(() => {
  if (itemValue.value.dialogType === 2) {
    let updatedItems = [...items.value];
    updatedItems.splice(1, 0, {
      type: 'view-all',
      icon: require('@/static/img/icon/send-video.svg'),
      text: t('coordinator.view_member'),
    });

    if (itemValue.value && itemValue.value.otherInfo.like) {
      updatedItems = updatedItems.map((item) => {
        if (item.type === 'favourite') {
          return {
            ...item,
            type: 'unlinke',
            icon: require('@/static/img/icon/send-video.svg'),
            text: t('coordinator.un_like'),
          };
        }
        return item;
      });
      return updatedItems;
    }
    if (route.query.tab_list === TAB_COORDINATION.COORDINATION) {
      updatedItems = updatedItems.filter((item) => item.type !== 'favourite');
    }
    return updatedItems;
  } else {
    if (itemValue.value && itemValue.value.otherInfo.like) {
      let updatedItems = items.value.map((item) => {
        if (item.type === 'favourite') {
          return {
            ...item,
            type: 'unlinke',
            icon: require('@/static/img/icon/send-video.svg'),
            text: t('coordinator.un_like'),
          };
        }
        return item;
      });
      return updatedItems;
    }
    if (route.query.tab_list === TAB_COORDINATION.COORDINATION) {
      let updatedItems = [...items.value];
      updatedItems = updatedItems.filter((item) => item.type !== 'favourite');
      return updatedItems;
    }
    return items.value;
  }
});

const handleClick = (type) => {
  if (type === 'view-all') {
    dispatch('coordinator/viewMember', TAB_WALKIE.VIEW_MEMBERS);
  } else if (type === 'favourite') {
    if (itemValue.value.tab === TAB_COORDINATION.CONTACT) {
      dispatch('coordinator/handleLikeContact', {
        contactedUserId: itemValue.value.otherInfo.peerId,
      }).then(() => {
        let value = {
          ...itemValue.value,
          otherInfo: {
            ...itemValue.value.otherInfo,
            like: true,
          },
          tab: TAB_COORDINATION.CONTACT,
        };
        dispatch('coordinator/selectValue', value);
      });
    } else if (itemValue.value.tab === TAB_COORDINATION.CHANNEL) {
      dispatch('coordinator/handleLikeChannel', {
        channelId: itemValue.value.otherInfo.peerId,
      }).then(() => {
        let value = {
          ...itemValue.value,
          otherInfo: {
            ...itemValue.value.otherInfo,
            like: true,
          },
          tab: TAB_COORDINATION.CHANNEL,
        };
        dispatch('coordinator/selectValue', value);
        dispatch('coordinator/getListChannels', {
          payload: { page: 0, size: 10, q: '', activeOnly: true },
          firstData: false,
        });
      });
    }
  } else if (type === 'unlinke') {
    if (itemValue.value.tab === TAB_COORDINATION.CONTACT) {
      dispatch('coordinator/handleUnLikeContact', {
        contactedUserId: itemValue.value.otherInfo.peerId,
      }).then(() => {
        let value = {
          ...itemValue.value,
          otherInfo: {
            ...itemValue.value.otherInfo,
            like: false,
          },
          tab: TAB_COORDINATION.CONTACT,
        };
        dispatch('coordinator/selectValue', value);
      });
    } else if (itemValue.value.tab === TAB_COORDINATION.CHANNEL) {
      dispatch('coordinator/handleUnLikeChannel', {
        channelId: itemValue.value.otherInfo.peerId,
      }).then(() => {
        let value = {
          ...itemValue.value,
          otherInfo: {
            ...itemValue.value.otherInfo,
            like: false,
          },
          tab: TAB_COORDINATION.CHANNEL,
        };
        dispatch('coordinator/selectValue', value);
        dispatch('coordinator/getListChannels', {
          payload: { page: 0, size: 10, q: '', activeOnly: true },
          firstData: false,
        });
      });
    }
  } else if (type === 'del-all') {
    let payload = {
      active: true,
      type: 'all',
    };
    dispatch('coordinator/isShowDelete', payload);
  }
};
const handleConnect = async () => {
  await handleCancel();
  let payload = {
    receiverId: itemValue.value.otherInfo.peerId,
    receiverType: itemValue.value.otherInfo.peerType,
    phoneType: 'voice',
  };
  dispatch('coordinator/createPhoneRoom', payload).then((data) => {
    localStorage.setItem('roomId', data.roomId);
    dispatch('coordinator/handleGetRoomID', data.roomId);
    dispatch('coordinator/joinChannel', {
      appId: window.VUE_APP_API_AGORA_ID,
      channel: data.roomId,
      token: null,
      uuid: null,
    });
    dispatch('coordinator/joinRoom', {
      roomId: data.roomId,
    });
    dispatch(
      'coordinator/handleChangeStatus',
      itemValue.value.otherInfo.peerId
    );
  });
};
const handleCancel = async () => {
  let value = {
    roomId: roomId.value,
  };
  await dispatch('coordinator/leaveRoom', value);
  await dispatch('coordinator/handleChangeStatus', null);
  await dispatch('coordinator/leaveChannel');
};
</script>

<style>

</style>
